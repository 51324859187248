
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class Icon extends Vue {
        @Prop({required: true, type: String}) private name!: string;
        @Prop() private width!: number;
        @Prop() private height!: number;
        @Prop() private fill!: string;


        get iconName(): string {
            if(this.name === 'event') return 'ticket';
            if(this.name === 'audience') return 'users-alt';
            if(this.name === 'behaviour') return 'head-side';
            if(this.name === 'dashboard') return 'dashboard';
            if(this.name === 'financial') return 'bill';
            // if(this.name === 'financial') return 'euro-circle';
            if(this.name === 'custom-report') return 'file-medical-alt';
            if(this.name === 'segment') return 'channel';
            if(this.name === 'segment-add') return 'channel-add';
            if(this.name === 'close') return 'times';
            if(this.name === 'up') return 'angle-up';
            if(this.name === 'down') return 'angle-down';
            if(this.name === 'menu') return 'ellipsis-v';
            if(this.name === 'info') return 'info-circle';
            if(this.name === 'add') return 'plus-circle';
            return this.name;
        }

        get iconWidth(): number {
            return this.width ?? 24;
        }

        get iconHeight(): number {
            return this.height ?? 24;
        }

        get iconFill(): string {
            return this.fill ?? '#273945';
        }

    }
