
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';


@Component
export default class ToggleSwitch extends Vue {
  private isChecked: boolean = false;
  @Prop() private value!: boolean;

  @Watch('value')
  valueChanged(newVal: boolean) {
    this.isChecked = newVal
  }

  @Emit("input")
  public onChange(): boolean {
    return this.isChecked;
  }

  mounted() {
    this.isChecked = this.value;
  }

}
