import {TranslateResult} from 'vue-i18n';
import {Condition, GroupCondition, ValueCondition} from '@/models/condition';

export enum SegmentType {
    new = 'new',
    prebuild = 'prebuild',
    static = 'static',
    dynamic = 'dynamic',
}

export interface SegmentSummery {
    id: string;
    name: string | TranslateResult;
    type: SegmentType;
    count?: number;
    percentage?: number;
}

export interface Segment {
    id: string | null;
    name: string | TranslateResult;
    type: SegmentType;
    conditions: Condition|GroupCondition|ValueCondition;
    // adAudiences: SegmentAdAudience[];
    // integrations: SegmentIntegration[] | null;
}


// export enum AdNetwork {
//     google = 'googleAd',
//     facebook = 'facebookAd',
// }
//
// export enum AdAudienceSyncStatus {
//     pending = 'pending',
//     synced = 'synced',
// }
//
// export interface SegmentAdAudience {
//     id: string;
//     // adNetworkAccountId: string;
//     network: AdNetwork;
//     audience: {
//         id: string;
//         name: string;
//     };
//     // customerSynced: number;
//     syncStatus: AdAudienceSyncStatus;
//     lastSyncOn: string;
// }


export enum IntegrationNetwork {
    mailchimp = 'mailchimp',
    google = 'googleAd',
    facebook = 'facebookAd',
}

export enum IntegrationSyncStatus {
    pending = 'pending',
    synced = 'synced',
}

// export interface SegmentIntegration {
//     id: string;
//     network: IntegrationNetwork;
//     syncStatus: IntegrationSyncStatus;
//     lastSyncOn: string;
// }

export interface SegmentIntegration {
    segmentId: string;
    integrationConfigId: string;
    integrationName: IntegrationNetwork;
    mailchimpSegmentName?: string;
}

export interface IntegrationConfig {
    id: string;
    clientId: string;
    integrationId: string;
    integrationName: string;
    name: string;
}

