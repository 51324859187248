
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import {Action, Getter} from 'vuex-class';
    import Icon from '@/components/Icon.vue';
    import ToggleSwitch from '@/components/Input/ToggleSwitch.vue';
    import {NavigationGroup, NavigationItem} from "@/models/navigation";
    // const coreModule = namespace('@/modules/core/index');
    // const namespace: string = 'core';

    @Component({
        components: {
            Icon,
          ToggleSwitch,
        }
    })
    export default class Navigation extends Vue {
        // @State navigation!: NavigationGroup;
        // @State('core') navigation!: NavigationGroup;
        @Getter('navigation') navigation!: NavigationGroup;
        @Getter('inEditMode') inEditMode!: boolean;
        @Getter('isEditor') isEditor!: boolean;
        @Getter('scopes') scopes!: string[];
        @Action('setEditMode') setEditMode: any;
        // @coreModule.Getter('navigation') navigation: NavigationGroup;

        get enableEditReportButton(): boolean {
          const isCustomReportRoute: boolean = (this.$route && String(this.$route.name).includes('report'));
          return this.isEditor && isCustomReportRoute;
        }

        public isActive(item: NavigationItem): boolean {
            if(item.name === this.$route.name || (this.$route.name && this.$route.name.includes(item.name))) {
                return true;
            }
            if(item.items) {
                for (let i = 0; i < item.items.length; i++) {
                    if(this.isActive(item.items[i])) {
                        return true;
                    }
                }
            }
            return false;
        }

        public created(): void {
          if(!this.enableEditReportButton) {
            this.setEditMode(false);
          }
        }

    }
