
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import Multiselect from 'vue-multiselect';

    @Component({
        components: {
            Multiselect,
        }
    })
    export default class SelectBox extends Vue {
        private v: Object|String|Number|null = null;
        @Prop() private value!: Object|String|Number;
        @Prop() private options!: Array<Object|String|Number>;
        @Prop({default: false}) private multiple!: boolean;


        get hasObjectOptions(): boolean {
            return this.options && Array.isArray(this.options) && typeof this.options[0] === 'object' && this.options[0] !== null;
        }

        @Watch('value')
        nameChanged(newVal: Object|String|Number|null) {
            this.v = newVal
        }

        @Emit("input")
        public onChange(event:any): any {
            return event;
        }

        mounted() {
            this.v = this.value;
        }

    }
