
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import VueDatePicker from 'vue2-datepicker';
    import moment from 'moment';
    import 'vue2-datepicker/index.css';
    import {DateRange, DateRangeString, instanceOfDateRange, instanceOfDateRangeString} from "@/models/date-context";

    @Component({
        components: {
            VueDatePicker,
        }
    })
    export default class DatePicker extends Vue {
        private v: Date|Array<Date>|null = null;
        @Prop() private value!: string|Date|DateRangeString|DateRange|null;
        @Prop({default: 'YYYY-MM-DD'}) private format!: string;
        @Prop({default: false}) private returnString!: boolean;
        @Prop({default: false}) private range!: boolean;
        @Prop({default: () => false}) private disabledDate!: (date: Date) => boolean;
        @Prop({default: false}) private disabled!: boolean;

        get disabledDateValidator(): (date: Date) => boolean {
            const defaultValidator = (date: Date) => {
                return false
            };
            return this.disabledDate ? this.disabledDate : defaultValidator;
        }

        @Watch('value')
        valueChanged(newVal: string|Date) {
            this.setValue(newVal);
        }

        @Emit("input")
        public onChange(date:Date|Array<Date>): string|Date|DateRangeString|DateRange {
            if(this.returnString && date instanceof Date) {
                return moment(date).format(this.format);
            }
            if(this.returnString && Array.isArray(date) && date[0] instanceof Date && date[1] instanceof Date) {
                return {min: moment(date[0]).format(this.format), max: moment(date[1]).format(this.format)};
            }
            if(date instanceof Date) {
                return date;
            }
            if(Array.isArray(date) && date[0] instanceof Date && date[1] instanceof Date) {
                return {min: date[0], max: date[1]};
            }
            throw new Error('Invalid date or date range.');
        }

        public setValue(value:string|Date|DateRangeString|DateRange|null): any {
            // if(value instanceof Date) {
            //     this.v = value;
            // } else {
            //     this.v = moment(value).toDate();
            // }
            if(value instanceof Date) {
                this.v = value;
            } else if(value === null) {
                this.v = null;
            } else if(instanceOfDateRange(value)) {
                this.v = [value.min, value.max];
            } else if(instanceOfDateRangeString(value)) {
                this.v = [moment(value.min).toDate(), moment(value.max).toDate()];
            } else {
                this.v = moment(value).toDate();
            }

        }

        mounted() {
            this.setValue(this.value);
        }

    }
