import {Job} from '@/store/models';
import store from '@/store';

class Loading {

    public isLoading(jobIds: string[]): boolean {
        return store.getters.jobs.map((job: Job) => job.id).some((jobId: string) => jobIds.indexOf(jobId) >= 0);
    }

    public isLoadingJobType(jobTypes: string[]): boolean {
        return store.getters.jobs.map((job: Job) => job.type).some((jobType: string) => jobTypes.indexOf(jobType) >= 0);
    }

}

export default new Loading();
