
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import Multiselect from 'vue-multiselect';
    import Icon from "@/components/Icon.vue";

    @Component({
        components: {
            Multiselect,
            Icon,
        }
    })
    export default class CheckBox extends Vue {
        private isChecked: boolean = false;
        @Prop() private value!: boolean;
        @Prop() private parent!: string;
        @Prop({default: '-'}) private label!: string;
        @Prop({default: false}) private showLabelText!: boolean;

        @Watch('value')
        nameChanged(newVal: boolean) {
            this.isChecked = newVal
        }

        @Emit("input")
        public onChange(): boolean {
            this.isChecked = !this.isChecked;
            return this.isChecked;
        }

        mounted() {
            this.isChecked = this.value;
        }

    }
