
    import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
    import Checkbox from '@/components/Input/Checkbox.vue';
    import {RouterParams, TableColumn} from "@/models/table";
    import jsonPath from '@/util/JsonPath';

    @Component({
        components: {
            Checkbox,
        }
    })
    export default class BasicTable extends Vue {
        private searchTerm?: string|null = null;
        private selectedRows: Array<any> = [];
        @Prop() private data!: Array<any>;
        @Prop() private columns!: TableColumn[];
        @Prop() private selected!: Array<any>;
        @Prop({default: true}) private showHeader!: boolean;
        @Prop({default: false}) private enableSelectRow!: boolean;
        @Prop({default: false}) private enableSearch!: boolean;
        @Prop({default: 'Search'}) private searchPlaceholder!: string;
        @Prop({default: 'id'}) private trackBy!: string; // key of data items that is used for tracking paymentMethodRows
        @Prop({default: 'name'}) private searchOn!: string; // key of data items that is used for searching

        get filteredData(): Array<any> {
            return this.data.filter((d) => {
                if(this.searchTerm && this.searchTerm !== '') {
                    return String(d[this.searchOn]).toLowerCase().includes(this.searchTerm.toLowerCase())
                }
                return true;
            })
        }

        get noData(): boolean {
            if(this.filteredData && this.filteredData.length > 0) {
                return false;
            }
            return true;
        }

        public hasLink(column: TableColumn): boolean {
            return column.link !== undefined;
        }

        public isSelected(row: any): boolean {
            return this.selectedRows.includes(row[this.trackBy]);
        }

        public getValue(column: TableColumn, row: any): string {
            if(column.format) {
                return column.format(jsonPath.getValue(row, column.key, ''));
            }
            return jsonPath.getValue(row, column.key, '');
        }

        public getRouterLinkConfig(column: TableColumn, row: any): {name: string, params: object} {
            if(column && column.link) {
                return {name: column.link.routeName, params: this.getLinkParams(column, row)};
            }
            throw new Error('No link property found on column.');
        }

        public getLinkParams(column: TableColumn, row: any): object {
            let params: RouterParams = {};
            if(column && column.link && column.link.params) {
                column.link.params.forEach(p => {
                    params[p.paramKey] = jsonPath.getValue(row, p.valueKey, '');
                })
            }
            return params;
        }

        public onSelectRow(row: any): void {
            const index = this.selectedRows.indexOf(row[this.trackBy]);
            if(index === -1) {
                this.selectedRows.push(row[this.trackBy]); // add to selected
            } else {
                this.selectedRows.splice(index, 1); // remove from selected
            }
            this.emitSelect();
        }

        public initSelectRow(selected: Array<any>): void {
            if(selected) {
                this.selectedRows = selected.map(item => item[this.trackBy]);
            }
        }

        @Watch('selected')
        selectedChanged(newVal: Array<any>) {
            // this.selectedRows = newVal.map(item => item[this.trackBy]);
            this.initSelectRow(newVal);
        }

        @Emit("select")
        public emitSelect(): Array<any> {
            return this.data.filter(item => this.selectedRows.includes(item[this.trackBy]))
        }

        mounted() {
            this.initSelectRow(this.selected);
        }

    }
