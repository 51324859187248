import { render, staticRenderFns } from "./Panel.vue?vue&type=template&id=1bc37134&scoped=true&"
import script from "./Panel.vue?vue&type=script&lang=ts&"
export * from "./Panel.vue?vue&type=script&lang=ts&"
import style0 from "./Panel.vue?vue&type=style&index=0&id=1bc37134&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc37134",
  null
  
)

export default component.exports