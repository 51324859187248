
    import { Component, Vue, Prop } from 'vue-property-decorator';
    // import { Action } from 'vuex-class';
    import Navigation from '@/components/Navigation.vue';

    @Component({
        components: {
            Navigation,
        }
    })
    export default class BaseLayout extends Vue {
        @Prop() private isLoading?: boolean;
        @Prop() private inEditMode?: boolean;
        // @Action('fetchPeriods') fetchPeriods: any;
        // @Action('fetchDateContext') fetchDateContext: any;
        // @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;

        get pageStyleClass(): string {
          return this.inEditMode ? 'page__edit' : 'page';
        }

        get viewStyleClass(): string {
          return this.inEditMode ? 'view view__edit' : 'view';
        }
    }
