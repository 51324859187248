import {GroupCondition} from '@/models/condition';


class JsonPath {

    public getValue(model: any, path: string, def: string): any {
        path = path || '';
        model = model || {};
        def = typeof def === 'undefined' ? '' : def;
        const parts = path.split('.');
        if (parts.length > 1 && typeof model[parts[0]] === 'object') {
            return this.getValue(model[parts[0]], parts.splice(1).join('.'), def);
        } else {
            return model[parts[0]] || def;
        }
    }

}

export default new JsonPath();
